.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background-color: white;
  width: 100%;
  padding: 2rem;
  max-width: 40rem;
  height: auto;
  min-height: 30rem;
  z-index: 7;
}

@media screen and (max-width: 1280px) {
  .modal {
    top: 100%;
  }
}

@media screen and (max-width: 425px) {
  .modal {
    width: 80%;
  }
}
