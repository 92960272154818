.checkout {
  margin: 2rem auto;
  width: 100%;
}

.checkout h1 {
  margin-bottom: 1rem;
}

.checkout h1,
.checkout p {
  text-align: center;
  width: 100%;
}

.checkout p {
  padding: 1rem;
}

.checkoutHeader {
  border-bottom: 1px solid black;
}

.cart table {
  width: 100%;
}

.checkoutHeader,
.cart {
  width: 100%;
  text-align: left;
}

.checkout td {
  padding: 0.5rem;
}

.cart th,
.cart td,
.checkoutHeader th,
.checkoutHeader td {
  width: 22%;
}

.cartItem td img {
  display: block;
  width: 100%;
}

.cartBtn {
  cursor: pointer;
}

@media screen and (max-width: 425px) {
  .cart th,
  .cart td,
  .checkoutHeader th,
  .checkoutHeader td {
    width: 22%;
  }

  .cartItem td img {
    display: block;
    width: 80px;
    height: 50px;
  }
}
