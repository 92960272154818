.paymentDetails {
  display: block;
  width: 100%;
  max-width: 100rem;
  padding: 0;
  margin: 2rem auto;
}

.group {
  margin: 0 auto 2rem;
  width: 80%;
}

.group h2 {
  padding-bottom: 1rem;
}
