/* h1 {
  margin-left: 20%;
  padding: 1rem 10px;
} */

#dashboard {
  margin-left: 20%;
  padding: 1rem 10px;
}

@media screen and (max-width: 768px) {
  #dashboard {
    margin: 0 auto;
  }
}
