.authWrapper {
  display: block;
  width: 100%;
  max-width: 40rem;
  border: 1px solid black;
  margin: 4rem auto 6rem;
}

.wrap {
  padding: 10px;
}

.authWrapper h2 {
  font-size: 2rem;
  line-height: 1;
  font-family: 400;
  text-transform: uppercase;
  text-align: center;
  display: block;
  width: 100%;
  margin: 0 auto 0rem;
}

.error {
  font-size: x-large;
  font-weight: 400;
  color: red;
  margin: 0rem auto 1.5rem;
}
