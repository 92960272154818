.formRow {
  text-align: center;
}

input {
  display: block;
  line-height: 1;
  width: 98%;
  font-weight: 400;
  text-align: left;
  padding: 10px 0px 10px 5px;
  margin: 10px auto;
  border: 1px solid #9e9e9e;
  font-size: 1.5rem;
  outline: none;
}

.checkoutInput select {
  display: block;
  line-height: 1;
  width: inherit;
  font-weight: 400;
  text-align: left;
  padding: 10px 5px;
  margin: 10px auto;
  border: 1px solid #9e9e9e;
  font-size: 1.5rem;
  outline: none;
}
