.horizontalNav {
  width: 80%;
  margin-left: 20%;
  border-bottom: 1px solid #d3d3d3;
}

.menu {
  display: inline-block;
  padding-left: 10px;
}

.menu ul,
.menu li {
  list-style-type: none;
  display: inline-block;
  margin-right: 1rem;
}

.menu a,
.menu span {
  display: block;
  width: 100%;
  font-size: 1.6rem;
  line-height: 2;
  color: black;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .horizontalNav {
    margin: 0 auto;
    width: 100%;
  }
}
