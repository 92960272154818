.products-div {
  display: flex;
  flex-wrap: wrap;
  margin-left: -1rem;
}

.products-div a {
  color: black;
  text-decoration: none;
}

.product {
  margin: 0px auto 2rem;
  display: flex;
  flex-direction: column;
  width: 30%;
}

.product img {
  width: 100%;
  height: 250px;
  object-fit: contain;
}

.product ul li {
  display: block;
  width: 100%;
  list-style-type: none;
  margin: 0 0 0.5rem;
  text-align: left;
}

.details {
  padding: 1%;
}

.name {
  font-size: 1.3rem;
  line-height: 1.2;
  font-weight: 500;
}

.price,
.size {
  font-size: 1.2rem;
  line-height: 1;
  font-weight: 400;
}

.bottom {
  margin-top: auto;
}

@media screen and (max-width: 768px) {
  .product {
    width: 42%;
  }

  .products {
    margin-left: 16px;
  }

  .products-div {
    margin-left: 0;
    justify-content: space-evenly;
  }

  .product img {
    height: 150px;
  }
}

@media screen and (max-width: 320px) {
  .product {
    width: 90%;
  }
}
