.payment-div {
  margin-left: 20%;
  padding: 1rem;
}

@media screen and (max-width: 768px) {
  .payment-div {
    margin-left: 0;
  }
}
