.order-div {
  margin-left: 20%;
  padding: 1rem;
}

@media screen and (max-width: 768px) {
  .order-div {
    margin: 0 auto;
  }
}
