.adminToolbar {
  background-color: black;
  display: inline-block;
  height: auto;
  width: 80%;
  position: relative;
  z-index: 2;
  margin-left: 20%;
  font-weight: bolder;
}

.adminToolbar ul,
.adminToolbar li {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.adminToolbar ul {
  float: right;
}

.adminToolbar li {
  display: inline-block;
}

.adminToolbar a {
  display: block;
  font-size: 1.6rem;
  line-height: 1;
  color: white;
  padding: 10px;
  transition: al 0.4s ease-in-out;
  text-decoration: none;
}

.adminToolbar a:hover {
  background-color: rgba(255, 255, 255, 0.6);
  color: black;
  transition: al 0.4s ease-in-out;
}

@media screen and (max-width: 768px) {
  .adminToolbar {
    width: 100%;
    margin-left: 0;
  }
}
