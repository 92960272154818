/* NAVIGATION BAR  */
* {
  margin: 0;
  padding: 0;
}

body {
  overflow-x: hidden;
}

ul {
  list-style-position: inside;
}

.format-div {
  margin-left: 20%;
  text-align: center;
}

#nav a {
  text-decoration: none;
  color: #818181;
}

#nav a:hover {
  color: white;
}

/* nav */
.nav {
  background-color: black;
  width: 20%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  overflow-x: hidden;
  display: inline-block;
}

.links-div {
  color: #818181;
  margin-top: 10%;
  padding: 2%;
}

.links-div a {
  display: block;
  padding: 4% 8%;
  font-size: 200%;
}

.links-div a:hover,
.links-div2 a:hover {
  color: #f1f1f1;
}

.links-div2 {
  color: #818181;
  margin-top: 40%;
  padding: 2%;
}

.links-div2 a {
  display: block;
  padding: 2% 0% 2% 8%;
  font-size: 140%;
}

/* nav2 */
.header {
  height: 4rem;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}

.wrap {
  position: relative;
  height: 100%;
  max-width: 1450px;
  margin: 0 auto;
}

.nav2 {
  position: absolute;
  top: 50%;
  right: 10px;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%);
}

.nav2 li {
  display: inline-block;
  list-style-type: none;
  margin-right: 1.5rem;
}

.nav2 li:last-child {
  margin-right: 0;
}

.nav2 a,
.nav2 span {
  font-size: 1.5rem;
  line-height: 1;
  color: black;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
}

.searchBar-div {
  margin-left: 20%;
  display: flex;
  align-items: center;
  width: 80%;
}

.search-button {
  background-color: orangered;
  border: 1px solid orangered;
  padding: 1% 2%;
  font-size: 150%;
  cursor: pointer;
}

.search-button:hover {
  color: white;
}

.shoppingCart-div {
  margin-left: 20%;
  display: flex;
  align-items: center;
  margin-right: 2%;
  font-size: 150%;
}

.active {
  color: white;
}

@media screen and (max-width: 425px) {
  .nav2 li:nth-child(1) {
    display: none;
  }
}
/* home layout */
.home-layout {
  margin-left: 20%;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin-top: 1%;
}

.shrink {
  transition: 1s ease-in-out;
}

.shrink:hover {
  -webkit-transform: scale(0.9);
  -ms-transform: scale(0.9);
  transform: scale(0.9);
}

.sneaker-div,
.apparel-div,
.collectible-div {
  width: 24.6%;
  border-radius: 6px;
  height: 41vh;
  margin: 1%;
  background-size: cover;
  color: white;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.sneaker-div {
  background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.4)),
    url("sneakers.jpeg");
}

.apparel-div {
  background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.4)),
    url("clothing.jpeg");
}

.collectible-div {
  background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.4)),
    url("collectibles.jpeg");
}

.tote-div {
  width: 77.8%;
  border-radius: 6px;
  margin: 1%;
  height: 42vh;
  background-image: url("blacktote.JPG"), url("totes.JPG"), url("whitetote.JPG");
  background-size: 33.4% 42vh, 33.4% 42vh, 33.4% 42vh;
  background-repeat: no-repeat, no-repeat, no-repeat;
  background-position: left, center, right;
  position: relative;
  cursor: pointer;
}

.tote-inner-div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.2),
    rgba(0, 0, 0, 0.4)
  );
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

/* MOBILE NAVBAR */
.mobile-nav {
  display: none;
  align-items: center;
  justify-content: space-between;
  padding: 3% 3%;
  background-color: black;
  color: white;
}

.mobile-nav h1 {
  font-size: 250%;
  font-weight: 900;
}

.mobile-nav-overlay {
  z-index: 1;
  position: fixed;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.95);
  overflow-x: hidden;
  top: 0;
  left: -100%;
  color: white;
  width: 100%;
  transition: 1.3s;
}

.mobile-nav-overlay.active {
  left: 0;
  transition: 1.3s;
}

.mobile-nav-overlay-content {
  margin: auto;
  position: relative;
  text-align: center;
  padding-top: 15%;
}

#bars:hover,
#close-btn:hover {
  color: orangered;
}

/* POLICY  & CONTACT */
.policy-div,
.contact-div {
  margin-left: 20%;
  padding: 2%;
}

.shippingPolicy-div {
  margin-bottom: 3%;
}

.policy-background {
  display: flex;
  align-items: center;
  color: whitesmoke;
  background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.65)),
    url("shipping.jpeg");
  border-radius: 6px;
  height: 20vh;
  background-size: cover;
  background-position: center center;
  margin-bottom: 3%;
  padding: 2%;
}

.contact-background {
  display: flex;
  align-items: center;
  color: whitesmoke;
  background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.5)),
    url("skyline.jpeg");
  border-radius: 6px;
  height: 20vh;
  background-size: cover;
  background-position: 0% 70%;
  margin-bottom: 3%;
  padding: 2%;
}

.shippingPolicy-div h1,
.contact-div h1 {
  font-size: 270%;
  margin-bottom: 1%;
}

.shippingPolicy-div h2,
.returnPolicy-div h2 {
  font-size: 160%;
  letter-spacing: 4px;
}

.question-div {
  margin: 1% 0% 2% 0%;
}

.question {
  font-size: 130%;
  font-weight: 380;
}

.answer {
  padding: 0.1% 0% 0% 0%;
  line-height: 2em;
  font-size: 120%;
  font-weight: 200;
}

.contact-div div {
  margin-bottom: 3%;
}

.contact-div div a {
  margin: 1% 0% 2% 0%;
  font-size: 125%;
  font-weight: 400;
  color: green;
}

.policy-background-p,
.contact-background-p {
  font-size: 180%;
  font-weight: 200;
  margin: 0% 0% 0% 0%;
}

.contact-div-heading {
  font-size: 160%;
}

.contact-div-content {
  line-height: 2em;
}

/* MY ORDERS */
.myOrder {
  margin-left: 20%;
  padding: 2%;
}

.myOrder div {
  margin: 2% 6% 4% 0%;
}

.myOrder div p {
  padding: 4% 0%;
  font-size: 125%;
}
@media screen and (max-width: 768px) {
  .nav {
    display: none;
  }

  .home-layout {
    margin: 0%;
  }

  .searchBar-div {
    margin: 0%;
    width: 98%;
  }

  .shoppingCart-div {
    display: none;
  }

  .sneaker-div,
  .apparel-div,
  .collectible-div {
    width: 31.3333333%;
  }

  .tote-div {
    width: 98%;
  }

  .mobile-nav {
    display: flex;
  }

  .policy-div,
  .contact-div {
    margin: 0%;
    padding: 2% 3%;
  }

  .question {
    font-size: 110%;
  }

  .answer {
    font-size: 100%;
  }

  .contact-div h1 {
    font-size: 300%;
  }

  .contact-div div {
    margin: 3% 0% 5% 0%;
  }

  .links-div {
    margin-top: 2%;
  }

  .links-div a {
    padding: 2%;
  }

  .links-div2 {
    color: #818181;
    margin-top: 5%;
  }

  .links-div2 a {
    display: block;
    padding: 2% 0% 2% 0%;
    font-size: 140%;
  }

  .format-div {
    margin: 0 auto;
    width: 80%;
  }
}

@media screen and (max-width: 600px) {
  .sneaker-div,
  .apparel-div,
  .collectible-div {
    width: 98%;
  }

  .tote-div {
    width: 98%;
    background-image: url("totes.JPG");
    background-size: cover;
    background-repeat: no-repeat;
  }

  .question-div {
    margin: 2% 0% 4% 0%;
  }

  .mobile-nav h1 {
    font-size: 220%;
  }

  .shippingPolicy-div h1,
  .contact-div h1 {
    font-size: 210%;
    margin-bottom: 1%;
  }

  .policy-background-p,
  .conatct-background-p {
    font-size: 120%;
    font-weight: 200;
    margin: 0% 0% 0% 0%;
  }
}

@media screen and (max-width: 425px) {
  .mobile-nav h1 {
    font-size: 170%;
  }

  .mobile-nav {
    padding: 4% 3%;
  }
}

/* Layouts - admin and dashboard */
