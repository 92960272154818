.containerDiv {
  margin-left: 20%;
  padding: 1rem;
}

.product-card {
  max-width: 50rem;
  margin: 0 auto 10rem;
}

.hero {
  display: block;
  width: 100%;
  margin: 2rem auto;
}

.hero img {
  display: block;
  width: 100%;
  margin: 0;
}

.productDetails ul,
.productDetails li {
  list-style-type: none;
  margin: 0 auto 1rem;
}

.productDetails h1 {
  margin: 0;
}

.addToCart {
  max-width: 20rem;
}

.productDetails span {
  font-weight: bold;
  font-size: larger;
}

@media screen and (max-width: 768px) {
  .containerDiv {
    margin: 0 auto;
    padding: 1rem;
  }
}
