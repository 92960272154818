.btn {
  display: block;
  margin: 1rem auto;
  padding: 1rem 10px;
  border: 0;
  color: white;
  background: black;
  text-transform: uppercase;
  font-size: 1.2rem;
  line-height: 1;
  outline: none;
  font-weight: 300;
  cursor: pointer;
  width: 100%;
}
