.socialSignin {
  margin: 0.5rem auto 0;
}

.links {
  display: block;
  width: 100%;
  margin: 1.5rem auto 0;
  text-align: left;
}

.links a {
  text-decoration: none;
  cursor: pointer;
  color: black;
  font-size: larger;
}
