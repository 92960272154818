#admin {
  margin-left: 20%;
  padding: 0 10px;
}

.admin .callToActions {
  display: inline-block;
  width: 100%;
  padding: 0;
  margin: 0 auto;
}

.admin ul,
.admin li {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.admin li {
  display: inline-block;
}

.manageProducts h1 {
  margin-top: 1.5rem;
}

.manageProducts table table tr:nth-child(even) {
  background-color: whitesmoke;
}

.manageProducts table tr td {
  padding: 1rem;
}

.manageProducts th {
  text-align: initial;
}
.manageProducts .thumb {
  width: 15rem;
  margin: 0 auto;
}
.addNewProductForm h2 {
  font-size: 2.2rem;
  line-height: 1;
  font-family: 400;
  text-transform: uppercase;
  text-align: center;
  display: block;
  width: 100%;
  margin: 0 auto 0rem;
}

@media screen and (max-width: 768px) {
  #admin {
    margin: 0 auto;
    padding: 0 10px;
    overflow: auto;
  }
}
