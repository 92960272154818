.userProfile {
  display: inline-block;
  margin: 0.5rem auto;
  padding: 0.5rem 10px 0px;
}

.userProfile ul,
.userProfile li {
  list-style-type: none;
}

.userProfile li {
  display: block;
}

.img {
  display: block;
  width: 4rem;
  margin: 0 auto;
}

.userProfile img {
  display: block;
  width: 100%;
}

.displayName {
  display: block;

  text-align: center;
  margin: 1rem auto;
  font-size: 1.8rem;
  line-height: 1;
  text-transform: uppercase;
}
