.shoppage {
  margin-left: 20%;
  padding: 1rem 1rem 10px;
}

@media screen and (max-width: 768px) {
  .shoppage {
    margin: 0 auto;
  }
}
