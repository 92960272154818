body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* NAVIGATION BAR  */
* {
  margin: 0;
  padding: 0;
}

body {
  overflow-x: hidden;
}

ul {
  list-style-position: inside;
}

.format-div {
  margin-left: 20%;
  text-align: center;
}

#nav a {
  text-decoration: none;
  color: #818181;
}

#nav a:hover {
  color: white;
}

/* nav */
.nav {
  background-color: black;
  width: 20%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  overflow-x: hidden;
  display: inline-block;
}

.links-div {
  color: #818181;
  margin-top: 10%;
  padding: 2%;
}

.links-div a {
  display: block;
  padding: 4% 8%;
  font-size: 200%;
}

.links-div a:hover,
.links-div2 a:hover {
  color: #f1f1f1;
}

.links-div2 {
  color: #818181;
  margin-top: 40%;
  padding: 2%;
}

.links-div2 a {
  display: block;
  padding: 2% 0% 2% 8%;
  font-size: 140%;
}

/* nav2 */
.header {
  height: 4rem;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}

.wrap {
  position: relative;
  height: 100%;
  max-width: 1450px;
  margin: 0 auto;
}

.nav2 {
  position: absolute;
  top: 50%;
  right: 10px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.nav2 li {
  display: inline-block;
  list-style-type: none;
  margin-right: 1.5rem;
}

.nav2 li:last-child {
  margin-right: 0;
}

.nav2 a,
.nav2 span {
  font-size: 1.5rem;
  line-height: 1;
  color: black;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
}

.searchBar-div {
  margin-left: 20%;
  display: flex;
  align-items: center;
  width: 80%;
}

.search-button {
  background-color: orangered;
  border: 1px solid orangered;
  padding: 1% 2%;
  font-size: 150%;
  cursor: pointer;
}

.search-button:hover {
  color: white;
}

.shoppingCart-div {
  margin-left: 20%;
  display: flex;
  align-items: center;
  margin-right: 2%;
  font-size: 150%;
}

.active {
  color: white;
}

@media screen and (max-width: 425px) {
  .nav2 li:nth-child(1) {
    display: none;
  }
}
/* home layout */
.home-layout {
  margin-left: 20%;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin-top: 1%;
}

.shrink {
  transition: 1s ease-in-out;
}

.shrink:hover {
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
}

.sneaker-div,
.apparel-div,
.collectible-div {
  width: 24.6%;
  border-radius: 6px;
  height: 41vh;
  margin: 1%;
  background-size: cover;
  color: white;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.sneaker-div {
  background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.4)),
    url(/static/media/sneakers.6cfa1980.jpeg);
}

.apparel-div {
  background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.4)),
    url(/static/media/clothing.c186a69e.jpeg);
}

.collectible-div {
  background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.4)),
    url(/static/media/collectibles.cb6e793f.jpeg);
}

.tote-div {
  width: 77.8%;
  border-radius: 6px;
  margin: 1%;
  height: 42vh;
  background-image: url(/static/media/blacktote.9437c5fa.JPG), url(/static/media/totes.bd748742.JPG), url(/static/media/whitetote.aa267cf2.JPG);
  background-size: 33.4% 42vh, 33.4% 42vh, 33.4% 42vh;
  background-repeat: no-repeat, no-repeat, no-repeat;
  background-position: left, center, right;
  position: relative;
  cursor: pointer;
}

.tote-inner-div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.2),
    rgba(0, 0, 0, 0.4)
  );
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

/* MOBILE NAVBAR */
.mobile-nav {
  display: none;
  align-items: center;
  justify-content: space-between;
  padding: 3% 3%;
  background-color: black;
  color: white;
}

.mobile-nav h1 {
  font-size: 250%;
  font-weight: 900;
}

.mobile-nav-overlay {
  z-index: 1;
  position: fixed;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.95);
  overflow-x: hidden;
  top: 0;
  left: -100%;
  color: white;
  width: 100%;
  transition: 1.3s;
}

.mobile-nav-overlay.active {
  left: 0;
  transition: 1.3s;
}

.mobile-nav-overlay-content {
  margin: auto;
  position: relative;
  text-align: center;
  padding-top: 15%;
}

#bars:hover,
#close-btn:hover {
  color: orangered;
}

/* POLICY  & CONTACT */
.policy-div,
.contact-div {
  margin-left: 20%;
  padding: 2%;
}

.shippingPolicy-div {
  margin-bottom: 3%;
}

.policy-background {
  display: flex;
  align-items: center;
  color: whitesmoke;
  background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.65)),
    url(/static/media/shipping.203fec80.jpeg);
  border-radius: 6px;
  height: 20vh;
  background-size: cover;
  background-position: center center;
  margin-bottom: 3%;
  padding: 2%;
}

.contact-background {
  display: flex;
  align-items: center;
  color: whitesmoke;
  background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.5)),
    url(/static/media/skyline.3b3d8c4d.jpeg);
  border-radius: 6px;
  height: 20vh;
  background-size: cover;
  background-position: 0% 70%;
  margin-bottom: 3%;
  padding: 2%;
}

.shippingPolicy-div h1,
.contact-div h1 {
  font-size: 270%;
  margin-bottom: 1%;
}

.shippingPolicy-div h2,
.returnPolicy-div h2 {
  font-size: 160%;
  letter-spacing: 4px;
}

.question-div {
  margin: 1% 0% 2% 0%;
}

.question {
  font-size: 130%;
  font-weight: 380;
}

.answer {
  padding: 0.1% 0% 0% 0%;
  line-height: 2em;
  font-size: 120%;
  font-weight: 200;
}

.contact-div div {
  margin-bottom: 3%;
}

.contact-div div a {
  margin: 1% 0% 2% 0%;
  font-size: 125%;
  font-weight: 400;
  color: green;
}

.policy-background-p,
.contact-background-p {
  font-size: 180%;
  font-weight: 200;
  margin: 0% 0% 0% 0%;
}

.contact-div-heading {
  font-size: 160%;
}

.contact-div-content {
  line-height: 2em;
}

/* MY ORDERS */
.myOrder {
  margin-left: 20%;
  padding: 2%;
}

.myOrder div {
  margin: 2% 6% 4% 0%;
}

.myOrder div p {
  padding: 4% 0%;
  font-size: 125%;
}
@media screen and (max-width: 768px) {
  .nav {
    display: none;
  }

  .home-layout {
    margin: 0%;
  }

  .searchBar-div {
    margin: 0%;
    width: 98%;
  }

  .shoppingCart-div {
    display: none;
  }

  .sneaker-div,
  .apparel-div,
  .collectible-div {
    width: 31.3333333%;
  }

  .tote-div {
    width: 98%;
  }

  .mobile-nav {
    display: flex;
  }

  .policy-div,
  .contact-div {
    margin: 0%;
    padding: 2% 3%;
  }

  .question {
    font-size: 110%;
  }

  .answer {
    font-size: 100%;
  }

  .contact-div h1 {
    font-size: 300%;
  }

  .contact-div div {
    margin: 3% 0% 5% 0%;
  }

  .links-div {
    margin-top: 2%;
  }

  .links-div a {
    padding: 2%;
  }

  .links-div2 {
    color: #818181;
    margin-top: 5%;
  }

  .links-div2 a {
    display: block;
    padding: 2% 0% 2% 0%;
    font-size: 140%;
  }

  .format-div {
    margin: 0 auto;
    width: 80%;
  }
}

@media screen and (max-width: 600px) {
  .sneaker-div,
  .apparel-div,
  .collectible-div {
    width: 98%;
  }

  .tote-div {
    width: 98%;
    background-image: url(/static/media/totes.bd748742.JPG);
    background-size: cover;
    background-repeat: no-repeat;
  }

  .question-div {
    margin: 2% 0% 4% 0%;
  }

  .mobile-nav h1 {
    font-size: 220%;
  }

  .shippingPolicy-div h1,
  .contact-div h1 {
    font-size: 210%;
    margin-bottom: 1%;
  }

  .policy-background-p,
  .conatct-background-p {
    font-size: 120%;
    font-weight: 200;
    margin: 0% 0% 0% 0%;
  }
}

@media screen and (max-width: 425px) {
  .mobile-nav h1 {
    font-size: 170%;
  }

  .mobile-nav {
    padding: 4% 3%;
  }
}

/* Layouts - admin and dashboard */

.shoppage {
  margin-left: 20%;
  padding: 1rem 1rem 10px;
}

@media screen and (max-width: 768px) {
  .shoppage {
    margin: 0 auto;
  }
}

.products-div {
  display: flex;
  flex-wrap: wrap;
  margin-left: -1rem;
}

.products-div a {
  color: black;
  text-decoration: none;
}

.product {
  margin: 0px auto 2rem;
  display: flex;
  flex-direction: column;
  width: 30%;
}

.product img {
  width: 100%;
  height: 250px;
  object-fit: contain;
}

.product ul li {
  display: block;
  width: 100%;
  list-style-type: none;
  margin: 0 0 0.5rem;
  text-align: left;
}

.details {
  padding: 1%;
}

.name {
  font-size: 1.3rem;
  line-height: 1.2;
  font-weight: 500;
}

.price,
.size {
  font-size: 1.2rem;
  line-height: 1;
  font-weight: 400;
}

.bottom {
  margin-top: auto;
}

@media screen and (max-width: 768px) {
  .product {
    width: 42%;
  }

  .products {
    margin-left: 16px;
  }

  .products-div {
    margin-left: 0;
    justify-content: space-evenly;
  }

  .product img {
    height: 150px;
  }
}

@media screen and (max-width: 320px) {
  .product {
    width: 90%;
  }
}

.btn {
  display: block;
  margin: 1rem auto;
  padding: 1rem 10px;
  border: 0;
  color: white;
  background: black;
  text-transform: uppercase;
  font-size: 1.2rem;
  line-height: 1;
  outline: none;
  font-weight: 300;
  cursor: pointer;
  width: 100%;
}

.formRow {
  display: inline-block;
  width: 100%;
}

.formRow label {
  display: block;
  width: 100%;
  text-align: left;
}

.formRow select {
  display: block;
  width: auto;
  float: left;
  font-size: 1.5rem;
  line-height: 1;
  font-weight: 400;
  text-align: left;
  padding: 10px 0px;
  margin: 10px auto;
  border: none;
  outline: none;
  cursor: pointer;
}

.formRow {
  text-align: center;
}

input {
  display: block;
  line-height: 1;
  width: 98%;
  font-weight: 400;
  text-align: left;
  padding: 10px 0px 10px 5px;
  margin: 10px auto;
  border: 1px solid #9e9e9e;
  font-size: 1.5rem;
  outline: none;
}

.checkoutInput select {
  display: block;
  line-height: 1;
  width: inherit;
  font-weight: 400;
  text-align: left;
  padding: 10px 5px;
  margin: 10px auto;
  border: 1px solid #9e9e9e;
  font-size: 1.5rem;
  outline: none;
}

.authWrapper {
  display: block;
  width: 100%;
  max-width: 40rem;
  border: 1px solid black;
  margin: 4rem auto 6rem;
}

.wrap {
  padding: 10px;
}

.authWrapper h2 {
  font-size: 2rem;
  line-height: 1;
  font-family: 400;
  text-transform: uppercase;
  text-align: center;
  display: block;
  width: 100%;
  margin: 0 auto 0rem;
}

.error {
  font-size: x-large;
  font-weight: 400;
  color: red;
  margin: 0rem auto 1.5rem;
}

.socialSignin {
  margin: 0.5rem auto 0;
}

.links {
  display: block;
  width: 100%;
  margin: 1.5rem auto 0;
  text-align: left;
}

.links a {
  text-decoration: none;
  cursor: pointer;
  color: black;
  font-size: larger;
}

.formWrap {
  margin: 3rem auto 0;
}

/* h1 {
  margin-left: 20%;
  padding: 1rem 10px;
} */

#dashboard {
  margin-left: 20%;
  padding: 1rem 10px;
}

@media screen and (max-width: 768px) {
  #dashboard {
    margin: 0 auto;
  }
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background-color: white;
  width: 100%;
  padding: 2rem;
  max-width: 40rem;
  height: auto;
  min-height: 30rem;
  z-index: 7;
}

@media screen and (max-width: 1280px) {
  .modal {
    top: 100%;
  }
}

@media screen and (max-width: 425px) {
  .modal {
    width: 80%;
  }
}

#admin {
  margin-left: 20%;
  padding: 0 10px;
}

.admin .callToActions {
  display: inline-block;
  width: 100%;
  padding: 0;
  margin: 0 auto;
}

.admin ul,
.admin li {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.admin li {
  display: inline-block;
}

.manageProducts h1 {
  margin-top: 1.5rem;
}

.manageProducts table table tr:nth-child(even) {
  background-color: whitesmoke;
}

.manageProducts table tr td {
  padding: 1rem;
}

.manageProducts th {
  text-align: left;
  text-align: initial;
}
.manageProducts .thumb {
  width: 15rem;
  margin: 0 auto;
}
.addNewProductForm h2 {
  font-size: 2.2rem;
  line-height: 1;
  font-family: 400;
  text-transform: uppercase;
  text-align: center;
  display: block;
  width: 100%;
  margin: 0 auto 0rem;
}

@media screen and (max-width: 768px) {
  #admin {
    margin: 0 auto;
    padding: 0 10px;
    overflow: auto;
  }
}

.checkout {
  margin: 2rem auto;
  width: 100%;
}

.checkout h1 {
  margin-bottom: 1rem;
}

.checkout h1,
.checkout p {
  text-align: center;
  width: 100%;
}

.checkout p {
  padding: 1rem;
}

.checkoutHeader {
  border-bottom: 1px solid black;
}

.cart table {
  width: 100%;
}

.checkoutHeader,
.cart {
  width: 100%;
  text-align: left;
}

.checkout td {
  padding: 0.5rem;
}

.cart th,
.cart td,
.checkoutHeader th,
.checkoutHeader td {
  width: 22%;
}

.cartItem td img {
  display: block;
  width: 100%;
}

.cartBtn {
  cursor: pointer;
}

@media screen and (max-width: 425px) {
  .cart th,
  .cart td,
  .checkoutHeader th,
  .checkoutHeader td {
    width: 22%;
  }

  .cartItem td img {
    display: block;
    width: 80px;
    height: 50px;
  }
}

.cart-div {
  margin-left: 20%;
  padding: 1rem;
}

@media screen and (max-width: 768px) {
  .cart-div {
    margin: 0 auto;
    padding: 0;
  }
}

.paymentDetails {
  display: block;
  width: 100%;
  max-width: 100rem;
  padding: 0;
  margin: 2rem auto;
}

.group {
  margin: 0 auto 2rem;
  width: 80%;
}

.group h2 {
  padding-bottom: 1rem;
}

.payment-div {
  margin-left: 20%;
  padding: 1rem;
}

@media screen and (max-width: 768px) {
  .payment-div {
    margin-left: 0;
  }
}

.order-div {
  margin-left: 20%;
  padding: 1rem;
}

@media screen and (max-width: 768px) {
  .order-div {
    margin: 0 auto;
  }
}

.adminToolbar {
  background-color: black;
  display: inline-block;
  height: auto;
  width: 80%;
  position: relative;
  z-index: 2;
  margin-left: 20%;
  font-weight: bolder;
}

.adminToolbar ul,
.adminToolbar li {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.adminToolbar ul {
  float: right;
}

.adminToolbar li {
  display: inline-block;
}

.adminToolbar a {
  display: block;
  font-size: 1.6rem;
  line-height: 1;
  color: white;
  padding: 10px;
  transition: al 0.4s ease-in-out;
  text-decoration: none;
}

.adminToolbar a:hover {
  background-color: rgba(255, 255, 255, 0.6);
  color: black;
  transition: al 0.4s ease-in-out;
}

@media screen and (max-width: 768px) {
  .adminToolbar {
    width: 100%;
    margin-left: 0;
  }
}

.userProfile {
  display: inline-block;
  margin: 0.5rem auto;
  padding: 0.5rem 10px 0px;
}

.userProfile ul,
.userProfile li {
  list-style-type: none;
}

.userProfile li {
  display: block;
}

.img {
  display: block;
  width: 4rem;
  margin: 0 auto;
}

.userProfile img {
  display: block;
  width: 100%;
}

.displayName {
  display: block;

  text-align: center;
  margin: 1rem auto;
  font-size: 1.8rem;
  line-height: 1;
  text-transform: uppercase;
}

.horizontalNav {
  width: 80%;
  margin-left: 20%;
  border-bottom: 1px solid #d3d3d3;
}

.menu {
  display: inline-block;
  padding-left: 10px;
}

.menu ul,
.menu li {
  list-style-type: none;
  display: inline-block;
  margin-right: 1rem;
}

.menu a,
.menu span {
  display: block;
  width: 100%;
  font-size: 1.6rem;
  line-height: 2;
  color: black;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .horizontalNav {
    margin: 0 auto;
    width: 100%;
  }
}

.containerDiv {
  margin-left: 20%;
  padding: 1rem;
}

.product-card {
  max-width: 50rem;
  margin: 0 auto 10rem;
}

.hero {
  display: block;
  width: 100%;
  margin: 2rem auto;
}

.hero img {
  display: block;
  width: 100%;
  margin: 0;
}

.productDetails ul,
.productDetails li {
  list-style-type: none;
  margin: 0 auto 1rem;
}

.productDetails h1 {
  margin: 0;
}

.addToCart {
  max-width: 20rem;
}

.productDetails span {
  font-weight: bold;
  font-size: larger;
}

@media screen and (max-width: 768px) {
  .containerDiv {
    margin: 0 auto;
    padding: 1rem;
  }
}

