.formRow {
  display: inline-block;
  width: 100%;
}

.formRow label {
  display: block;
  width: 100%;
  text-align: left;
}

.formRow select {
  display: block;
  width: auto;
  float: left;
  font-size: 1.5rem;
  line-height: 1;
  font-weight: 400;
  text-align: left;
  padding: 10px 0px;
  margin: 10px auto;
  border: none;
  outline: none;
  cursor: pointer;
}
